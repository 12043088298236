const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.ozminerals.forwoodsafety.com',
    APP_CLIENT_ID: '5gq6s3bd06spphv7nje6toka1q',
    APIGATEWAY: 'https://2haf40mph8.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'ap-southeast-2',
    URL: 'https://1sf1rq6eqa.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'prod',
    VERSION: 'incidents-container-1.8.0',
    HOSTNAME: 'https://incidents.ozminerals.forwoodsafety.com',
    INCIDENTS_ACCESS_ROLE: 'IncidentsAccess',
    CREATE_INCIDENT_URL: 'https://iframe.ozminerals.forwoodsafety.com/node/add/isi',
    INCIDENT_LIST_URL: 'https://iframe.ozminerals.forwoodsafety.com/isi-list',
    IFRAME_ORIGIN_URL: 'https://iframe.ozminerals.forwoodsafety.com'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;

